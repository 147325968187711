@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500;600;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600&display=swap");

$gris_claro: #fafafa;
$main_color: #5043dd;
$dark_color: #2a2651;
$gray_background: #f5f6f7;

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
* {
    font-family: "Montserrat";
    image-orientation: from-image !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 1000px) {
    ::-webkit-scrollbar {
        width: 6px;
    }
}
input[type="file"] {
    opacity: 0 !important;
}
/* Start of Login styles*/
.App {
    background: white;
    width: 100vw;
    height: 100vh;
    min-width: 300px;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
}
.loginApp {
    overflow: hidden !important;
    background: #f3f4f9;
}
.loginForm {
    height: 390px;
    width: 350px;
    max-width: 80%;
    min-width: 300px;
    margin: auto;
    margin-top: calc(45vh - 210px);
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0,0,0,.02), 0 5px 42px -8px rgba(0,0,0,.1);
    padding: 22px;
    position: relative;
}
.onboardingForm {
    width: 400px;
    height: 300px !important;
}
#settingsPanel {
    position: fixed;
    width: 360px;
    right: 106px;
    height: 260px;
    top: 55px;
    background: white;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    animation: fadeInsettingsPanel 0.1s;
    transform-origin: right;
    @keyframes fadeInsettingsPanel {
        0% {
            opacity: 0;
            transform: scaleY(0.9);
        }
        100% {
            opacity: 1;
            transform: scaleY(1);
        }
    }
}
.notificationsContainer {
    overflow-y: auto;
    display: block;
    height: calc(100% - 70px);
    width: calc(100%);
    padding-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    .notificationAvatar {
        height: 26px;
        width: 26px;
        position: absolute;
        left: 17px;
        top: 22px;
    }
    .notificationText {
        font-size: 12px;
        margin-top: 12px;
        margin-bottom: -2px;
        color: gray;
    }
    .unreadNotificationContainer {
        width: calc(100% - 81px);
        display: block;
        padding: 22px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        background-color: #704eff07;
        padding-left: 60px;
        border-left: 2px solid #704eff97;
    }
    .notificationContainer {
        width: calc(100% - 80px);
        display: block;
        padding: 22px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        background-color: white;
        padding-left: 60px;
    }
    .notificationTitle {
        font-weight: 600;
        font-size: 13px;
        margin: 0px;
        margin-bottom: 8px;
        color: $dark_color;
        width: calc(100% - 56px);
    }
    .notificationDescription {
        font-weight: 400;
        font-size: 12px;
        margin: 0px;
        // color: gray;
        padding: 14px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
        margin-top: 16px;
        background-color: white;
        width: calc(100% - 28px);
        cursor: pointer;
        position: relative;
        &:hover {
            svg {
                stroke: #704eff;
                stroke-width: 1.6;
                right: -6px;
            }
        }
        svg {
            position: absolute;
            right: -3px;
            top: 10px;
            stroke: lightgray;
            font-size: 10px;
            height: 22px;
            transition: .3s;
        }
    }
    .notificationDate {
        font-weight: 400;
        font-size: 10px;
        margin: 0px;
        position: absolute;
        right: 20px;
        top: 22px;
        color: gray;
    }
    
}
.notificationsContainer::-webkit-scrollbar {
    width: 6px !important;
}
#userPanel {
    position: fixed;
    width: 360px;
    right: 0px;
    height: 280px;
    top: 55px;
    background: white;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    animation: fadeInsettingsPanel 0.1s;
    transform-origin: right;
    @keyframes fadeInsettingsPanel {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scaleY(1);
        }
    }
}
.userPanelAvatar {
    height: 80px;
    width: 80px;
    margin: auto;
    margin-top: 26px;
}
.panelUsername {
    margin: auto;
    margin-top: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
}
.panelUserEmail {
    margin: auto;
    margin-top: 4px;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
}
.panelLogoutButton {
    margin: auto;
    margin-top: 46px;
    font-weight: 400;
    font-size: 14px;
    width: 140px;
    padding: 10px 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        color: $main_color;
    }
}
.settingsLink {
    width: calc(100% - 40px);
    display: block;
    padding: 22px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
}
.settingsLink:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}
.settingsLink svg {
    // background-color: $main_color;
    stroke: #704eff;
    border-radius: 4px;
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
}
.settingsText {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    vertical-align: middle;
    margin-left: 12px;
    margin-top: -2px !important;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.8);
    height: 38px;
    padding-top: 1px;
}
.settingsText p {
    position: absolute;
    margin-top: 9px;
    width: 300px;
    margin-left: 10px;
    color: #5F5C80;
    font-size: 14px;
    font-weight: 400;
    left: -10px;
    top: 12px;
}
.onboardingError {
    color: rgba(255, 0, 0, 0.7);
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}
.onboardingUser {
    color: gray;
    text-align: center;
}
.onboardingUser span {
    color: blue;
    cursor: pointer;
}
.loginForm h3 {
    color: gray;
}
.loginContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.logoLogin {
    display: block;
    margin: auto;
    height: 40px;
    margin-top: 20px;
    border-radius: 6px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1px;
    color: $dark_color;
    transform: scaleX(0.9);
}
.loginContainer fieldset {
    border: 1px solid lightgray !important;
    height: 58px;
    padding-top: 0px;
}
.onboardingContainer fieldset {
    border: 1px solid lightgray !important;
    height: 58px;
    padding-top: 0px;
}
.onboardingContainer .loginInput {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}
.loginContainer .loginForm .okInput {
    border: 2px solid rgba(0, 200, 100, 0.6) !important;
}
.loginContainer .loginForm .wrongInput {
    border: 2px solid rgba(250, 0, 0, 0.6) !important;
}
.loginContainer label {
    color: gray !important;
    font-family: "Montserrat";
}
.onboardingContainer label {
    color: gray !important;
    font-family: "Montserrat";
}
.loginContainer .MuiTouchRipple-root,
.onboardingContainer .MuiTouchRipple-root {
    background: $main_color;
    box-shadow: none !important;
    color: white !important;
}
.loginContainer .MuiButton-label,
.onboardingContainer .MuiButton-label {
    z-index: 1;
}
.loginInput {
    width: 96%;
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-bottom: 20px !important;
    margin-top: 0px !important;
    border-color: gray !important;
    font-family: "Montserrat" !important;
    transform: scale(0.94);
    transform-origin: top;
    padding-left: 20px;
}
.loginButton {
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 20px !important;
    margin-top: 50px !important;
    padding: 10px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    opacity: 1; 
    &:hover {
      opacity: 0.9; 
    }
}
.disabledLoginButton {
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 20px !important;
    margin-top: 50px !important;
    padding: 10px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    opacity: 0.5;
    cursor: default !important;
}
.loginText {
    margin: auto;
    text-align: center;
    margin-top: 40px;
    font-size: 15px;
    color: rgba(0,0,0,0.7);
    display: none;
}
.loginRegisterLink {
    color: blue;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.MuiButton-label {
    font-family: "Montserrat" !important;
}
#loginHeadline {
    font-size: 16px !important;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: 'Inter';
    color: rgba(0, 0, 60, 0.6);
    font-weight: 400;
    font-size: 18px;
}
.loginLink {
    position: absolute;
    width: 200px;
    text-align: left;
    left: 40px;
    right: 0px;
    top: 292px;
    font-size: 13px;
    text-decoration: none;
    font-weight: 500;
    color: rgba(0, 0, 60, 0.6);
    cursor: pointer;
    &:hover {
        color: $main_color;
    }
}
#signUpErrorMessage {
    font-size: 16px;
    color: rgba(255, 70, 70, 0.9);
    font-weight: 500;
    padding: 0px 20px;
    width: calc(100% - 40px);
}
.pagePlaceholder {
    color: rgba(0, 0, 0, 0.7);
    position: absolute;
    right: 50px;
    top: 152px;
}
.onboardingLabel {
    color: rgba(0, 0, 0, 0.6);
    left: 10%;
    text-align: center;
    width: 80%;
    font-size: 14px;
    position: absolute;
    transform: translateY(120px);
}
.onboardingInput {
    transform: translateY(20px);
    margin-top: -5px !important;
}
#onboardingErrorMessage {
    position: absolute;
    color: red;
    opacity: 0.7;
    font-size: 14px;
    width: calc(100% - 20px);
    text-align: center;
}
@media (max-width: 1000px) {
    .loginContainer {
        width: 100% !important;
        height: 90%;
    }
    .leftMenu {
        background: white !important;
        box-shadow: none !important;
        z-index: 2 !important;
        border-right: 1px solid rgba(0,0,0,.1);
    }
    .menuButton {
        position: fixed !important;
        z-index: 5;
        top: -3px !important;
        left: 0px !important;
    }
    .pageLogo {
        text-align: center;
        margin: auto !important;
        margin-top: 13px !important;
        display: block;
        height: 80px;
        width: 100px !important;
        transform: translateX(-7px);
        font-weight: 600;
    }
    #navbar {
        background: white !important;
        box-shadow: 0px 7px 14px -16px rgba(0, 0, 0, 0.25);
        height: 55px !important;
    }
    .pageContainer {
        margin-top: 120px !important;
    }
    .pageContainerFullWidth {
        margin-top: 120px !important;
    }
}
@media (min-width: 1000px) {
    .menuButton {
        display: none;
    }
    .pageContainerFullWidth {
        padding-left: 240px !important;
        height: 90%;
        transition-duration: 0.5s;
        transition-property: all;
        position: relative;
        margin-top: 90px !important;
    }
    .leftMenuClosed {
        width: 240px !important;
        margin-left: 0px !important;
        padding-top: 90px;
        height: calc(100% - 90px) !important;
    }
}

/* End of login styles*/

/* Start of left menu styles*/
#navbar {
    background: rgba(255,255,255,0.8);
    height: 88px;
    width: 100vw !important;
    position: fixed;
    top: 0px;
    z-index: 2;
    backdrop-filter: blur(4px);
    // box-shadow: 0px 7px 14px -16px $dark_color;
}
.navbarContainer {
    padding-left: 0px;
    width: calc(90vw - 160px);
    height: 100%;
    max-width: 1400px;
    transform: translateX(10%);
    margin: auto;
    margin-top: 12px;
}
@media (max-width: 1000px) {
    .navbarContainer {
        padding-left: 62px;
        transform: translateX(calc(8% + 10px));
        margin-top: 0px;
    }
}
@media (max-width: 600px) {
    .navbarContainer {
        display: none;
    }
}
.userButton {
    height: 29px;
    width: 29px;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    cursor: pointer;
    right: 8px;
    top: 13px;
    &:hover {
        opacity: 0.6;
    }
}
.settingsButton {
    position: absolute;
    right: 106px;
    border-radius: 20px;
    font-size: 20px;
    top: 12px;
    padding: 4px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
        background: rgba(0, 0, 0, 0.06) !important;
        color: $main_color;
    }
}
.notificationsButton {
    position: absolute;
    right: 60px;
    border-radius: 20px;
    font-size: 20px;
    top: 12px;
    padding: 4px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
        background: rgba(0, 0, 0, 0.06) !important;
        color: $main_color;
    }
}
.notificationsBadge {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    position: absolute;
    right: 63px;
    top: 15px;
    background-color: $main_color;
    border: 2px solid white;
}
.leftMenu {
    background: #fafafa;
    position: fixed;
    left: -5px;
    top: 0px;
    height: 100% !important;
    width: 240px;
    transition-duration: 0.5s;
    transition-property: all;
    z-index: 105;
    padding-top: 0px;
    padding-left: 0px;
    border-right: 1px solid rgba(0,0,0,.1);
}
.leftMenuClosed {
    background: #fafafa;
    position: fixed;
    left: -5px;
    top: 0px;
    height: 100% !important;
    width: 240px;
    margin-left: -260px;
    transition-duration: 0.5s;
    transition-property: all;
    z-index: 105;
    padding-top: 0px;
    padding-left: 0px;
    border-right: 1px solid rgba(0,0,0,.1);
}
.pageLogo {
    font-size: 22px;
    margin-top: 13px;
    margin-left: 85px;
    font-weight: 500;
    width: 100px !important;
    display: block;
    color: $main_color;
    z-index: 999;
}
.pageLogoMenu {
    font-size: 18px;
    margin-top: 16px;
    margin-left: 95px;
    margin-bottom: 60px;
    font-weight: 600;
    width: 100px !important;
    display: block;
    color: white;
    z-index: 999;
}
.primaryButton {
    padding: 10px 22px;
    border-radius: 24px;
    background: $main_color;
    -webkit-box-shadow: 0px 0px 3px 0px $main_color;
    -moz-box-shadow: 0px 0px 3px 0px $main_color;
    box-shadow: 0px 0px 3px 0px $main_color;
    border: none;
    color: white;
    font-weight: 800;
    margin: auto;
    display: block;
    font-size: 16px;
    margin-top: 40px;
    cursor: pointer;
    outline: none !important;
}
.primaryButton:hover {
    opacity: 0.6;
}
.leftMenu a,
.leftMenuClosed a {
    color: $main_color !important;
    padding: 12px;
    padding-top: 12px;
    padding-left: 46px !important;
    text-decoration: none !important;
    display: block;
}
.leftMenu a path,
.leftMenuClosed a path {
    // color: #151783 !important;
    color: $dark_color !important;
}
.leftMenu a:hover,
.leftMenuClosed a:hover {
    background: rgba(0, 0, 0, 0.02);
    cursor: pointer;
}
.leftMenu ul,
.leftMenuClosed ul {
    margin-top: 50px !important;
    padding-left: 0px;
}
.leftMenu a li,
.leftMenuClosed a li {
    list-style-type: none;
    color: $dark_color;
    text-decoration: none !important;
    padding-left: 14px;
    font-weight: 500;
    font-size: 13px;
    display: inline-block;
}
.leftMenu svg,
.leftMenuClosed svg {
    color: $dark_color;
    margin-right: 0px;
    margin-left: -14px;
    // margin-top: -1px;
    width: 20px !important;
    transform: translateY(1px);
    font-size: 20px;
    height: 20px;
    vertical-align: middle;
    transform: scaleY(0.94);
}
.selectedMenuItem svg {
    stroke: $main_color !important;
    stroke-width: 2;
}
.selectedMenuItem li {
    color: $main_color !important;
}
.leftMenu .currentUser,
.leftMenuClosed .currentUser {
    height: 50px !important;
    margin-top: 50px;
    width: 90%;
    padding: 14px;
    position: absolute;
    bottom: 0px;
    border-top: 1px solid $gris_claro;
}
.userAvatarContainer {
    height: 45px;
    width: 45px;
    margin-left: 18px;
    margin-top: 2px;
}
.userAvatar {
    width: 80%;
    height: calc(40% + 11px);
    padding: 10%;
    padding-top: calc(50% - 11px);
    background: lightgray;
    border-radius: 50%;
    color: white;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
}
.userImage {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
.leftMenu .userPrivileges,
.leftMenuClosed .userPrivileges {
    color: $main_color;
    font-weight: 500;
    font-size: 11px;
    position: absolute;
    top: 31px;
    left: 90px;
}
.leftMenu .username,
.leftMenuClosed .username {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    position: absolute;
    top: 6px;
    left: 90px;
    font-weight: 500;
    text-transform: "Capitalize";
}
.menuButton {
    padding: 8px;
    margin: 10px;
    height: 20px !important;
    width: 20px !important;
    color: gray;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.5s;
    transition-property: all;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.7;
    transform: translateY(3px);
    transform-origin: center;
}
.menuButton:hover {
    background: rgba(0, 0, 0, 0.05);
    transform: scale(1.1) translateY(3px);
    transform-origin: center;
    transition-duration: 0.5s;
    transition-property: all;
}
/* End of left menu styles*/

/* End of content styles*/
.pageContainer {
    padding-left: 240px;
    height: 81%;
    transition-duration: 0.5s;
    transition-property: all;
    position: relative;
    margin-top: 100px;
}
.pageContainerFullWidth {
    padding-left: 0px;
    height: 81%;
    transition-duration: 0.5s;
    transition-property: all;
    margin-top: 120px;
}
.columnContainer {
    // background:red;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    min-width: 320px;
}
.dashboardContainer {
    // background:red;
    padding: 30px 0px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    min-width: 320px;
    // border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.dashboardCard {
    background: white;
    border-radius: 10px;
    height: 100%;
    width: 32%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}
.card3 {
    width: 24% !important;
}
.card4 {
    width: 32% !important;
    height: 120px;
}
.card6 {
    width: 49% !important;
}
.card8 {
    width: 100%;
    margin-bottom: 20px;
}
.list8 {
    width: 74% !important;
    min-width: 300px;
}
.list12 {
    width: 100% !important;
    min-width: 300px;
}
.height200 {
    height: 340px;
}
.height160 {
    height: 160px;
}
.height180 {
    height: 180px;
}
.height500 {
    max-height: 72vh;
    overflow: hidden;
    padding-bottom: 20px;
}
@media (max-width: 1000px) {
    .columnContainer {
        margin-bottom: 0px;
    }
    .card3 {
        width: 49.3% !important;
    }
    .card4 {
        width: 100% !important;
        margin-bottom: 42px;
    }
    .card6 {
        width: 100% !important;
    }
    .card8 {
        width: 100% !important;
        height: 300px;
    }
    .height140 {
        min-height: 280px;
    }
    .height160 {
        height: 480px;
    }
    .height180 {
        height: 360px;
    }
}
@media (max-width: 800px) {
    .reportesDiv {
        display: none;
    }
    .list8 {
        width: 90vw !important;
        margin: auto;
    }
}
@media (max-width: 500px) {
    .reportesDiv {
        display: none;
    }
    .list8 {
        width: 100vw !important;
        margin: auto;
    }
}
@media (max-width: 1000px) {
    .pageContainer {
        padding-left: 0px !important;
    }
    .pageContainer {
        margin-left: 0px !important;
        transition-duration: 0.5s;
        transition-property: all;
    }
    .pageContent h1 {
        margin-top: -22px !important;
        font-weight: 600 !important;
        margin-bottom: 20px;
        text-align: center;
        margin-left: 0px !important;
        color: black !important;
        position: static !important;
        z-index: 0 !important;
        transform: translateY(-20px);
    }
    .organizationSelector {
        margin-top: 90px !important;
    }
}
.pageContent {
    width: 92%;
    max-width: 1400px;
    height: 100%;
    margin: auto;
    padding: 0px !important;
    margin-bottom: 20px;
}
.pageContent h1 {
    color: black;
    font-weight: 600;
    font-size: 22px;
    margin: 14px;
    margin-top: -6px;
    margin-left: 0px;
    position: fixed;
    top: 37px;
    z-index: 2;
    small {
        color: #b0b3c5;
        font-size: 18px;
        margin-left: 12px;
        font-weight: 500;
    }
}
.bigCard {
    background: white;
    width: 100%;
    height: 90% !important;
    border-radius: 8px;
    -webkit-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
}
.pageContent h3 {
    color: #9baeb8;
    transform: scaleY(0.97);
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.35px;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
}
.MuiPaper-root {
    border-radius: 8px !important;
    box-shadow: 0px 17px 23px -12px rgba(0, 0, 0, 0.1) !important;
}
thead th {
    font-weight: 800 !important;
}
.liveReportContainer {
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 9px;
}
.liveReportContent {
    transform: scale(0.94);
    transform-origin: top center;
}
.organizationSelector {
    height: 53px;
    width: calc(100% - 52px);
    margin: auto;
    padding: 0px 26px;
    padding-top: 4px;
    color: $dark_color !important;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: -30px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: center;
    text-transform: capitalize;
    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
}
.organizationSelector p {
    display: inline-block;
    vertical-align: middle;
    transform: translateY(1px);
    margin-top: 15px;
    white-space: nowrap;
    max-width: 62%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}
.organizationSelector .icon {
    margin-top: 2px;
    transform: translateY(1px);
    vertical-align: middle;
    margin-left: 10px;
    // color: gray;
    font-size: 10px;
    opacity: 0.6;
    height: 10px;
}
.organizationAvatar {
    background:#2a2651;
    height: 20px;
    width: 21px;
    padding-top: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 14px;
    margin-top: 4px;
    border-radius: 2px;
    text-align: center;
    color: white;
    font-weight: 600;
}
.reportesDiv {
    width: 20%;
}
.reportesDiv h3 {
    margin-top: 0px;
}
.exportBtn {
    height: 30px;
    width: 100%;
    margin-top: 12px;
    text-align: center;
    padding-top: 12px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
    }
}
.exportBtn svg {
    margin-right: 6px;
}
.exportExcel {
    color: rgba(0, 155, 0, 0.7);
}
.exportPdf {
    color: rgba(255, 0, 0, 0.7);
}
.nuevoGasto {
    color: $main_color;
    margin-bottom: 25px;
}
.gastosList {
    overflow-y: auto;
    max-height: calc(80vh - 90px);
    overflow: auto;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(70% 0, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
        radial-gradient(70% 100%, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;

    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 46% 101%, rgba(0, 0, 100, 0.1), rgba(0, 0, 0, 0)) 0 100%;

    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 60px, 100% 60px, 100% 0px, 110% 12px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    .noResults {
        margin-top: 80px;
        text-align: center;
        color: lightgray;
        font-weight: 400;
        font-size: 18px;
    }
}
.gastosList::-webkit-scrollbar {
    transition: 0.3s;
    width: 6px;
}
.gastosList::-webkit-scrollbar-track {
    background: white;
}
.gastosList::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
}
.gastosList:hover::-webkit-scrollbar-thumb {
    background: #ddd;
    width: 10px;
}
.gastosList::-webkit-scrollbar-thumb:hover {
    background: #aaa;
    width: 10px;
}
.dashboardContainerSkeleton .pulse {
    opacity: 0.6;
}
.pulse {
    $from: #f5f5f5;
    $to: scale-color($from, $lightness: -10%);
    background: linear-gradient(-90deg, #fdfdfd 0%, #dfdfdf 50%, #fdfdfd 100%);
    background-size: 500% 500%;
    animation: pulse 1.5s ease-in-out infinite;
    @keyframes pulse {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
}
.fadeInCard {
    animation: fadeInCard 0.4s;
    @keyframes fadeInCard {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
.attachmentsSkeleton {
    width: calc(35% - 60px);
    display: inline-block;
    height: calc(100% - 126px);
    position: absolute;
    left: 30px;
    top: 96px;
    border-radius: 6px;
    opacity: 0.4;
}
.textSkeletonSmall {
    position: absolute !important;
    height: 12px;
    width: 30%;
    left: 78px;
    top: 45px;
    border-radius: 10px;
}
.textSkeleton {
    position: absolute !important;
    height: 12px;
    width: calc(90% - 70px);
    left: 78px;
    top: 20px;
    border-radius: 10px;
}
.avatarSkeleton {
    position: absolute !important;
    height: 40px;
    width: 40px;
    left: 20px;
    top: 18px;
    border-radius: 20px;
}
.SingleStatIconSkeleton {
    height: 52px;
    width: 52px;
    border-radius: 40px;
    margin-left: 23px;
    margin-top: 31px;
    margin-bottom: 32px;
    display: inline-block;
}
.dashboardCardSkeleton {
    width: 32%;
}
.dashboardContainerSkeleton {
    padding: 30px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    min-width: 320px;
    // border-top: 1px solid rgba(0, 0, 0, 0.07);
    justify-content: space-between;
}
.statSkeleton {
    display: inline-block;
    vertical-align: top;
}
.statValueSkeleton {
    height: 22px;
    width: 140px;
    margin-left: 24px;
    margin-top: 48px;
}
.statLabelSkeleton {
    height: 18px;
    width: 100px;
    margin-top: 14px;
    margin-left: 24px;
}
.chartSkeleton {
    width: calc(100% - 80px);
    height: 180px;
    margin-top: 80px;
    margin-left: 34px;
    opacity: 0.6;
}
.organizationSelect {
    position: absolute;
    top: 96px;
    left: 20px;
    width: 120%;
    min-height: 10px;
    padding-bottom: 80px;
    background: white;
    z-index: 99999999999999;
    border-radius: 5px;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 50, 0.14);
    overflow: hidden;
    animation: fadeInPanel 0.1s;
    transform-origin: right;
    @keyframes fadeInPanel {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}
.selectTitle {
    margin: 24px auto;
    width: calc(100% - 40px);
    font-size: 16px;
    font-weight: 600;
}
.selectItem {
    width: calc(100% - 40px);
    padding: 12px 20px;
    margin: 0px;
    cursor: pointer;
    color: $dark_color;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    &:hover {
        background: rgba(0, 0, 90, 0.04);
    }
}
.selectButton {
    color: $main_color;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 16px 0px;
    padding-top: 13px;
    position: absolute;
    bottom: -14px;
    left: 0px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    }
}
.listEnd {
    height: 40px;
}
.MuiTabs-root {
    overflow: visible !important;
}
.MuiTabs-scroller {
    margin-bottom: -1px;
}
.noContentImage {
    display: block;
    height: 300px;
    width: 300px;
    margin: auto;
}
.noContentImage img {
    width: 100%;
}
.MuiPickersDay-daySelected {
    background-color: $main_color !important;
}
.Mui-focused::after {
    border-color: gray !important;
    border-width: 1px !important;
}
.MuiFormLabel-root.Mui-focused {
    color: gray !important;
}
-webkit-scrollbar {
    display: none;
}
.unsavedChanges {
    padding-left: 20px !important;
    vertical-align: middle;
    margin-top: -1px;
}
.unsavedChanges path {
    transform: scale(0.45);
    transform-origin: center;
    fill: $main_color;
}
.ndfHFb-c4YZDc-nJjxad-nK2kYb-i5oIFb {
    display: none;
}
.layoutTooltip {
    width: 290px;
    // padding: 0px 10px;
    padding-bottom: 8px;
    overflow: hidden;
    z-index: 1000;
    pointer-events: auto;
    label {
        font-size: 12px;
        color: $dark_color;
        font-weight: 500;
        padding: 12px 12px;
        display: block;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    div {
        transition: 0.1s;
        padding: 4px 12px;
        font-size: 13px;
        width: calc(100% - 20px);
        margin: auto;
        cursor: pointer;
        color: gray;
        &:hover {
            background-color: #fafafa;
            color: $dark_color !important;
            svg {
                stroke: $dark_color !important;
            }
        }
    }
    p {
        display: inline-block;
        vertical-align: middle;
        margin: 10px;
    }
    svg {
        height: 20px;
        width: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 2px;
        stroke: gray;
    }
}
.layoutTooltipSelected {
    color: $dark_color !important;
    font-weight: 500;
    svg {
        stroke: $main_color !important;
    }
}
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.25);
    position: absolute;
    left: -3px;
    top: 50px;
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: $main_color;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.feedbackButton {
    position: absolute;
    bottom: 30px;
    left: 8%;
    width: calc(84% - 24px);
    padding: 8px 10px;
    font-size: 13px;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0,0,60,0.02);
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
    svg {
        margin-right: 8px;
        margin-left: -1px;
        stroke: $main_color;
    }
}
.plansButton {
    position: absolute;
    bottom: 80px;
    left: 8%;
    width: calc(84% - 24px);
    padding: 8px 10px;
    font-size: 13px;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0,0,60,0.02);
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
    svg {
        margin-right: 8px;
        margin-left: -1px;
        stroke: $main_color;
    }
}
.feedbackDialogContent {
    margin-top: 8px;
	margin-left: 4px;
	font-size: 14px;
	padding: 6px;
	width: 100%;
    min-width: 500px;
    .feedbackOption {
        width: calc(100% - 70px);
        margin-left: -30px;
        padding: 30px;
        padding-bottom: 20px;
        padding-top: 22px;
        border-top: 1px solid rgba(0,0,0,.05);
        &:hover {
            background-color: rgba(0,0,0,.02);
            cursor: pointer;
        }
        svg {
            height: 24px;
            vertical-align: middle;
            margin-top: -3px;
            stroke: $main_color;
        }
    }
    .feedbackOption:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    textarea {
        border: none;
        outline: none;
        padding: 16px 30px;
        width: calc(100% - 70px);
        margin-left: -30px;
        margin-top: 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        resize: none;
        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
        }
        &:focus {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
.feedbackDialogButton {
    position: absolute;
    bottom: 30px;
    right: 30px;
}
.feedbackClip {
    position: absolute;
    padding: 8px;
    border-radius: 20px;
    bottom: 30px;
    left: 30px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    &:hover {
        // opacity: 0.4;
        background-color: rgba(0, 0, 0, 0.05);
    }
}
.planSkeleton {
    height: 480px;
    width: 100%;
    opacity: 0.8;
}