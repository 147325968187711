.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: 16vh;
  top: 0px;
  left: 0px;
  z-index: 10000;
  backdrop-filter: blur(2px);
  animation: backdropAnimation 0.3s;
  @keyframes backdropAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.modalContainer {
  max-height: 370px;
  height: auto;
  width: 90%;
  max-width: 600px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 70px;
  border-radius: 8px;
  overflow: hidden;
  animation: zoomIn 0.3s;
  @keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
}
.unmountedBackdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: 16vh;
  top: 0px;
  left: 0px;
  z-index: 10000;
  backdrop-filter: blur(2px);
  animation: unmountBackdropAnimation 0.3s;
  @keyframes unmountBackdropAnimation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.unmountedModalContainer {
  max-height: 350px;
  height: auto;
  width: 90%;
  max-width: 600px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 70px;
  border-radius: 8px;
  overflow: hidden;
  animation: unmountModalAnimation 0.3s;
  @keyframes unmountModalAnimation {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.9);
    }
  }
}
.searchInput {
  width: 100%;
  outline: none;
  border: none;
  font-size: 17px;
  padding: 20px;
  border-bottom: 1px solid rgb(243, 242, 242);
}
.option {
  padding: 14px;
  padding-left: 20px;
  font-size: 15px;
  border-left: 2px solid transparent;
  cursor: pointer;
}
.selectedOption {
  padding: 14px;
  padding-left: 20px;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 2px solid #5043dd;
  cursor: pointer;
}
.optionsList {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 60px);
}
.optionsList::-webkit-scrollbar {
  display: none;
}
.contentPlaceholder {
  height: 50px;
  margin-bottom: 10px;
  $from: #f8f8f8;
  $to: scale-color($from, $lightness: -10%);
  background: linear-gradient(-90deg, #fafafa 0%, #fff 50%, #fafafa 100%);
  background-size: 500% 500%;
  animation: pulse 1s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}
