.container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	background: white;
	height: 80vh;
	width: 100%;
	border-radius: 6px;
	// border: 1px solid rgba(0, 0, 0, 0.07);
	background: white;
    margin: auto;
    width: 100%;
    border-radius: 10px;
    // box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
}
.searchBar {
	height: 42px;
	transition: .2s;
	// border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(0,0,0,0);
	color: rgba(0, 0, 0, 0.2);
	padding-left: 18px;
	padding-top: 8px;
	margin-bottom: 0px;
	border-left: 3px solid transparent;
	border-bottom: 1px solid rgba(0, 0, 0, .07);
	&:focus-within {
		border-bottom: 1px solid rgba(0, 0, 0, .2);
		margin-bottom: 20px;
	}
}
.searchIcon {
	margin-top: 8px;
	color: rgba(0, 0, 0, 0.4);
	margin-left: -7px;
}
.searchInput {
	display: inline-block;
	background-color: transparent;
	vertical-align: top;
	margin-top: -6px;
	margin-left: -40px;
	font-size: 14px;
	border: none;
	outline: none;
	width: calc(100% - 40px);
	padding-left: 60px;
	height: 100%;
}
.members {
	// border-right: 1px solid rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	overflow-x: hidden;
}
.member {
	height: 70px;
	width: 100%;
	// border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: .1s;
	&:hover {
		background-color: rgba(0, 0, 0, 0.03);
	}
	// border-bottom-right-radius: 8px;
	// border-top-right-radius: 8px;
}
.selectedMember {
	height: 70px;
	width: calc(100% - 3px);
	border-left: 3px solid#5043dd;
	// border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.03);
	transition: .1s;
	// border-bottom-right-radius: 8px;
	// border-top-right-radius: 8px;
}
.memberInfo {
	display: inline-block;
	vertical-align: middle;
	height: 70px;
	width: calc(100% - 60px);
}
.avatar {
	height: 36px;
	width: 36px;
	border-radius: 30px;
	margin-top: -1px;
	margin-bottom: 0px;
	margin-left: 12px;
	margin-right: 12px;
	display: inline-block;
	vertical-align: middle;
	@media (max-width: 600px) {
		margin-left: 12px;
	}
}
.username {
	display: inline-block;
	font-size: 13px;
	font-weight: 600;
	color: #222;
	vertical-align: top;
	margin-top: 15px;
}
.expensesAmount {
	font-size: 13px;
	margin-top: -8px;
	color: #5043dd;
	font-weight: 500;
	width: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.refundsContainer {
	transition: .5s;
	position: relative;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 8px;
	margin-left: 7%;
	padding-top: 8px;
	padding-left: 4px;
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 50, 0.06);
}
.rightHandHeader {
	padding-top: 14px;
	padding-left: 4px;
	height: 46px;
	// background-color: rgba(0, 0, 0, 0.02);
	// border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 13px;
	font-weight: 600;
}
.rightHandUser {
	display: inline-block;
	vertical-align: top;
	margin-top: -4px;
}
.rightHandName {
	display: inline-block;
	margin-top: 3px;
}
.rightHandDetails {
	color: gray;
	margin-top: -10px;
	font-weight: 400;
	font-size: 12px;
}
.rightHandFooter {
	position: absolute;
	left: 0px;
	bottom: 0px;
	padding-top: 24px;
	padding-left: 14px;
	height: 42px;
	width: calc(100% - 14px);
	// background-color: rgba(0, 0, 0, 0.02);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 13px;
	font-weight: 600;
}
.expenseToSelect {
	border-radius: 4px;
	margin-top: 10px;
	margin-left: 10px;
	width: calc(100% - 110px);
	padding: 4px;
	padding-left: 4px;
	padding-right: 80px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.04);
	font-size: 14px;
	position: relative;
}
.expenseAmount {
	position: absolute;
	top: 1px;
	right: 18px;
	font-weight: 600;
	color: #5043dd;
}
.expensesList {
	overflow-y: auto;
	height: calc(75vh - 104px);
	padding-bottom: 8px;
}
.refundButton {
	position: absolute;
	right: 18px;
	top: 13px;
}
.emptyState {
	font-size: 14px;
	width: 100%;
	height: 100px;
	margin-top: calc(50vh - 150px);
	text-align: center;
	color: rgba(0,0,0,0.2);
	font-weight: 500;
}
.userIcon {
	display: block;
	margin: auto;
	font-size: 60px;
	color: rgba(0, 0, 0, 0.05);
	margin-bottom: 30px;
}
.dialogButton {
    position: absolute;
    bottom: 42px;
    right: 42px;
}
.dialogContent {
    margin-top: 24px;
	margin-left: 4px;
	font-size: 14px;
	padding: 6px;
	width: 92%;
}
.historyButton {
	position: absolute;
	right: 12px;
	top: 12px;
	padding: 8px 14px;
	border-radius: 6px;
	font-size: 14px;
	color: gray;
	cursor: pointer;
	width: 10px;
	height: 18px;
	overflow: hidden;
	color: transparent;
	transition: .4s;
	white-space: nowrap;
	&:hover {
		background: rgba(0, 0, 0, 0.03);
		color: #5043dd;
		width: 180px;
		svg {
			color: #5043dd;
			font-size: 14px;
		}
	}
	svg {
		margin-right: 10px;
		color: lightgray;
		font-size: 18px;
	}
	// display: none;
}
.tableHeader {
	width: 100%;
	background: white;
	padding: 14px 0px;
	font-weight: 600;
	font-size: 12px;
	color: gray;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.column {
	display: inline-block;
	width: 90%;
	max-width: 180px;
	padding-left: 20px;
}
.actionsColumn {
	display: inline-block;
	width: 90%;
	max-width: 40px;
	padding-left: 20px;
}
.smallColumn {
	float: right;
	width: calc(10% + 13px);
	min-width: 90px;
	text-align: left;
}
.goBack {
	cursor: pointer;
	margin-bottom: 30px;
	margin-top: 10px;
	font-size: 14px;
	color: gray;
}
.arrowBack {
	margin-right: 10px;
}
.bankInfo {
	display: block;
	color: rgba(0, 0, 0, 0.8);
	margin-bottom: 8px;
	margin-top: 20px;
	font-weight: 500;
	margin-top: -20px;
	width: 450px;
	max-width: 90%;
	height: 500px;
	p {
		color: gray;
		display: block;
		margin-top: 40px;
		font-size: 13px;
	}
}
.bankInfo label {
	display: block;
	color: rgba(0,0,0,0.3);
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 8px;
	margin-top: 20px;
}
.historyTable {
	margin-top: 10px;
	overflow: hidden;
}
.historyList {
	overflow-y: auto;
    max-height: calc(80vh - 120px);      
    overflow: auto;
    background: /* Shadow covers */
    linear-gradient( white 30%, rgba(255, 255, 255, 0)), 
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(70% 0, farthest-side, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)), 
    radial-gradient(70% 100%, farthest-side, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;

    background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), 
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)), 
    radial-gradient(farthest-side at 46% 101%, rgba(0, 0, 100, .1), rgba(0, 0, 0, 0)) 0 100%;

    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 60px, 100% 60px, 100% 0px, 110% 12px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}