.container {
  transition: .5s;
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  bottom: 60px;
  right: 60px;
  z-index: 999999;
  border-radius: 6px;
  color: white;
  padding: 20px 26px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid lightgray;
  animation: fadein .5s;
  svg {
    margin-right: 16px;
    font-size: 18px;
    vertical-align: middle;
    color: rgba(0,250,200,0.9);
    margin-top: -2px;
  }
}
@keyframes fadein {
  from {
      opacity: 0;
      bottom: -160px;
  }
  to {
      opacity: 1;
  }
}
.containerHidden {
  transition: .5s;
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  bottom: -160px;
  right: 60px;
  z-index: 999999;
  border-radius: 6px;
  color: white;
  padding: 20px 26px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid lightgray;
  animation: fadein .5s;
  svg {
    margin-right: 16px;
    font-size: 18px;
    vertical-align: middle;
    margin-top: -2px;
  }
}
.action {
  color: #a08eff;
  background-color: rgba(255,255,255,0);
  padding: 4px 8px;
  border-radius: 3px;
  border: none;
  display: inline-block;
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
    background-color: rgba(255,255,255,0.1);
  }
}