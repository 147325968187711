.container {
    padding-bottom: 0px;
}
.groups {
    height: 75vh;
    overflow: auto;
    margin-top: 20px;
    margin-right: -46px;
}
@media (max-width: 768px) {
    .groups {
        height: 70vh;
        margin-right: 0px;
        padding-right: 10px;
        padding: 0px;
        overflow-x: hidden;
    }
}
.group {
    padding: 18px;
    position: relative;
    width: calc(100% - 36px);
    min-height: 50px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 50, 0.06);
    max-width: 600px;
    h2 {
        margin-top: 2px;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}
.groupSeparator {
    height: 1px;
    width: 30px;
    margin: 0px;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.1);;
    margin-bottom: 20px;
}
.usersAmount {
    margin-top: 0px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    display: block;
    color: gray;
    margin-bottom: 6px;
}
.userFilter {
    // max-width: 300px;
    margin-left: 0px;
    margin-top: 16px;
    overflow: hidden;
    padding-right: 20px;
    display: block;
    margin-bottom: 10px;
}
.avatar {
    height: 28px;
    width: 28px;
    border-radius: 30px;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid white;
    margin-right: -8px;
    overflow: hidden;
    float: left;
    z-index: 0px;
}
.groupsContainer {
    padding-bottom: 30px;
}
.emptyStateContainer {
    width: calc(100% - 20px);
    height: 73vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: #f4f5f6;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    h2 {
        font-size: 1.1rem;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }
    p {
        font-size: 1rem;
        font-weight: 400;
        margin: 10;
        padding: 0;
        max-width: 540px;
        color: #5F5C80;
        margin-bottom: 30px;
    }
}
.label {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
    color: rgba(0,0,0,0.5);
    font-weight: 400;
}
.textInput {
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    border-radius: 4px;
    padding: 14px;
    width: 400px;
    max-width: 92%;
    outline: none !important;
    transition: .3s all;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #2a2651;
    &:focus {
        border: 1px solid rgba(0,0,0,0.3);
    }
    &::placeholder {
        color: #5F5C80;
        // opacity: 0.4;
    }
}
.sectionTitle {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    display: inline-block !important;
    vertical-align: middle;
    font-weight: 400;
    margin-left: 8px;
    margin-bottom: 38px;
}
.backArrow {
    color: rgba(0, 0, 0, 0.3);;
    font-size: 12px;
    vertical-align: top;
    padding: 12px;
    margin-top: 4px;
    padding-left: 0px;
    cursor: pointer;
    &:hover {
        color: black;
    }
}
.saveButton {
    margin-left: 100px;
    float: right;
    margin-top: 3px;
}
.inputDiv {
    display: block;
}
.topSeparator {
    height: 0px;
    border-color: rgba(0,0,0,0.07);
    border-top: 0px;
    margin-top: -10px;
    margin-bottom: 40px;
}
// .pageHeader {
//     // background-color: #f4f5f6;
//     padding: 16px 22px;
//     border-radius: 6px;
//     margin-bottom: 40px;
//     margin-top: -8px;
// }
.deleteCategory {
    height: 20px;
    stroke: rgba(180, 180, 180, 1);
    vertical-align: middle;
    padding: 9px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        stroke: rgba(255, 60, 60, 1);
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.editCategory {
    height: 20px;
    stroke: rgba(180, 180, 180, 1);
    vertical-align: middle;
    padding: 9px;
    position: absolute;
    right: 48px;
    top: 8px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        stroke: black;
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.separator {
    height: 100px;  
}
.pageHeader {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 61px;
    width: 100%;
    margin-top: 0px;
    background-color: white;
}
.dialogContent {
    margin-top: 24px;
    margin-left: 0px;
    min-width: 380px;
    padding-left: 6px;
}
.modalText {
    margin-left: 6px;
    width: 400px;
}
.dialogButton {
    position: absolute;
    bottom: 42px;
    right: 40px;
}