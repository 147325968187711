.text {
    text-align: center;
    margin-top: 3vh;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
}
.icon {
    text-align: center;
    margin-top: 30vh;
    font-size: 16px;
    opacity: 0.4;
    transform: scale(1.2);
}
.button {
    text-align: center;
    margin-top: 5vh;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}
