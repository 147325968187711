.profileLeftCard {
    width: 500px;
    max-width: calc(100% - 194px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #fff;
    padding: 50px;
    margin-bottom: 20px;
    min-height: 100px;
    padding-left: 160px;
    position: relative;
}
.loadingProfileLeftCard {
    width: 500px;
    max-width: calc(100% - 194px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #fff;
    padding: 50px;
    margin-bottom: 20px;
    min-height: 100px;
    padding-left: 160px;
    position: relative;
    opacity: 0.3;
}
.userImage {
    height: 100px;
    width: 100px;
    background-size: cover;
    border-radius: 100%;
    position: absolute;
    left: 30px;
    top: 50px;
}
.goBack {
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 14px;
    color: gray;
    &:hover {
        color: #5043dd;
    }
}
.arrowBack {
    margin-right: 10px;
}
.username {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 600;
    color: #2a2651;
}
.roleBadge {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #5043dd;
    background-color: #5043dd11;
    display: inline-block;
    padding: 5px 8px;
    border-radius: 6px;
    font-size: 13px;
}
.email {
    color: gray;
    font-size: 13px;
    margin-top: -10px;
    margin-bottom: 0px;
}
.separator {
    margin: 0px;
    padding: 0px;
    height: 1px;
    // background-color: rgba(0, 0, 0, 0.06);
}
.actionButton {
    display: inline-block;
    margin-right: 10px;
}
.dialogContent {
    margin-top: 24px;
    margin-left: 0px;
    min-width: 380px;
    padding-left: 6px;
}
.modalText {
    margin-left: 6px;
    width: 400px;
}
.dialogButton {
    position: absolute;
    bottom: 42px;
    right: 40px;
}