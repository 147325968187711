.container {
    padding: 0px;
    // border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 8px 30px rgba(0,0,0,0.12);
    border-radius: 8px;
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: white;
    z-index: 100;
    animation: slideIn .2s linear;
    transform-origin: top right;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    50% {
        opacity: 0.6;
        transform: scale(0.99);
    }
    100% {
        opacity: 1;
    }
}
