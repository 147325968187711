.label {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
    color: rgba(0,0,50,0.6);
    font-weight: 400;
}
.linkLabel {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
    color: #5043dd;
    font-weight: 500;
    cursor: pointer;
}
.textInput {
    border: 1px solid rgba(0,0,0,0.1);
    background: white;
    border-radius: 4px;
    padding: 14px;
    width: 300px;
    max-width: 92%;
    outline: none !important;
    transition: .3s all;
    font-size: 14px;
    font-weight: 500;
    color: #2a2651;
    &:focus {
        border: 1px solid rgba(0,0,0,0.3);
    }
    &::placeholder {
        color: #2a2651;
        opacity: 0.4;
    }
}
.sectionTitle {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    display: inline-block !important;
    vertical-align: middle;
    font-weight: 400;
    margin-left: 8px;
    margin-bottom: 38px;
    cursor: pointer;
}
.backArrow {
    color: rgba(0, 0, 0, 0.3);;
    font-size: 12px;
    vertical-align: top;
    padding: 12px;
    margin-top: 4px;
    padding-left: 0px;
    cursor: pointer;
    &:hover {
        color: black;
    }
}
.saveButton {
    margin-left: 100px;
    float: right;
}
.inputDiv {
    display: block;
}
.topSeparator {
    height: 0px;
    border-color: rgba(0,0,0,0.07);
    border-top: 0px;
    margin-top: -10px;
    margin-bottom: 40px;
}
.options {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    max-height: 400px;
    overflow: auto;
}
.option {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #2a2651;
    &:hover {
        background: rgba(0,0,0,0.03);
    }
    &:last-child {
        border-bottom: 0px;
    }
    p {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        vertical-align: middle;
    }
}
.noOptions {
    padding: 86px;
    font-size: 14px;
    font-weight: 500;
    color: #aaa;
    text-align: center;
    background-color: #fefefe;
    p {
        font-size: 16px;
        color: #2a2651;
    }
}
.pageHeader {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 61px;
    width: 100%;
    margin-top: 0px;
    background-color: white;
    z-index: 1;
}
.dialogInput {
    border: 1px solid lightgray;
    background: white;
    border-radius: 4px;
    padding: 12px;
    width: calc(85% + 2px);
    outline: none !important;
    margin-left: 6px;
    margin-top: 6px;
}
.dialogLabel {
    margin-top: 20px;
    color: gray;
    margin-left: 6px;
    font-size: 13px;
}
.dialogButton {
    position: absolute;
    bottom: 42px;
    right: 40px;
}
.dialogContent {
    margin-top: 24px;
    margin-left: 0px;
    min-width: 380px;
    padding-left: 6px;
}
.loading {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    opacity: 0.5;
}