.textInput {
    transition: 0.2s;
    color: rgba(0, 0, 40, 0.6);
    border: none;
    outline: none;
    width: 100%;
    font-size: 36px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
    font-weight: 500;
}
.textInput::placeholder {
    color: #b0b3c5;
    font-weight: 400;
    transition: 0.2s;
}
.hiddenTextInput::placeholder {
    color: transparent;
}
.hiddenTextInput {
    transition: 0.6s;
    transition-property: height;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    outline: none;
    width: 100%;
    font-size: 36px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block;
    font-weight: 500;
    height: 0px;
    opacity: 0;
    caret-color: transparent;
    -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.4s; /* Firefox < 16 */
    -ms-animation: fadein 0.4s; /* Internet Explorer */
    -o-animation: fadein 0.4s; /* Opera < 12.1 */
    animation: fadein 0.4s;
}
.numberInput {
    color: rgba(0, 0, 40, 0.6);
    border: none;
    outline: none;
    width: 100%;
    font-size: 36px;
    padding-top: 20px;
    margin-top: 0px;
    padding-bottom: 0px;
    display: block;
    font-weight: 500;
}
.hiddenNumberInput {
    transition: 0s;
    transition-property: height;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    outline: none;
    width: 100%;
    font-size: 36px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block;
    font-weight: 500;
    opacity: 0;
    caret-color: transparent;
}
.item {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    margin-bottom: 40px;
    -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.6s; /* Firefox < 16 */
    -ms-animation: fadein 0.6s; /* Internet Explorer */
    -o-animation: fadein 0.6s; /* Opera < 12.1 */
    animation: fadein 0.6s;
    max-width: 230px;
}
.description {
    display: inline-block;
    label {
        color: rgba(0, 0, 0, 0.3);
        font-weight: 500;
        font-size: 13px;
        display: block;
        margin-top: 8px;
        margin-bottom: 7px;
    }
}
.editableDescription {
    min-width: 100px;
    display: inline-block;
    cursor: pointer;
    label {
        color: rgba(0, 0, 0, 0.3);
        font-weight: 500;
        font-size: 13px;
        display: block;
        margin-top: 8px;
        margin-bottom: 7px;
        cursor: pointer;
    }
    svg {
        margin-left: 6px;
        padding-bottom: 1px;
        font-size: 13px;
        color: lightgray;
        transition: 0.2s;
    }
    &:hover {
        color: black;
        svg {
            color: #5043dd;
        }
    }
}
@keyframes fadein {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
    }
}
@keyframes helper {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.itemsContent {
    padding-bottom: 140px;
}
.helper {
    position: fixed;
    right: 25px;
    color: rgba(0, 0, 0, 0.4);
    padding: 15px 30px;
    padding-bottom: 0px;
    max-width: 100%;
    border-radius: 6px;
    bottom: 25px;
    animation: helper 1s;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background-color: rgba(0, 0, 0, 0.02);
    width: 360px;
    max-width: 80%;
    p {
        margin-bottom: 30px;
        font-size: 14px;
        color: gray;
        // big {
        //     font-size: 26px;
        //     height: 20px;
        // }
        i {
            font-style: normal;
            border: 1px solid rgba(0, 0, 0, 0.4);
            padding: 2px 8px;
            border-radius: 2px;
            margin: 2px;
            transform: translateY(30px);
            margin-left: 2px;
            margin-right: 6px;
        }
    }
    b {
        text-decoration: underline;
        margin-left: 5px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            color: #5043dd;
        }
    }
}
.bottomShadow {
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 40px;
    width: 100vw;
    background-color: white;
    box-shadow: 0px 0px 55px 55px white;
}
.buttonOption {
    margin-top: 24px;
    margin-bottom: 15px;
    display: inline-block;
    padding: 12px 20px;
    border-radius: 6px;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: gray;
    font-weight: 500;
    transition: 0.3s;
    opacity: 0.6;
    cursor: pointer;
}
.selectedButtonOption {
    margin-top: 24px;
    margin-bottom: 15px;
    display: inline-block;
    padding: 12px 20px;
    border-radius: 6px;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: 0 4px 35px 0 rgba(23, 55, 87, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
    color: #5043dd;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
}
.buttons {
    margin-bottom: 16px;
}
.hiddenButtons {
    overflow: hidden;
    height: 0px;
}
.animatedSpace {
    animation: space 0.4s;
}
@keyframes space {
    from {
        height: 90px;
    }
    to {
        height: 0px;
    }
}
.fileSelectorContainer {
    .filePlaceholder {
        transition: 0.3s;
        height: 150px;
        width: 150px;
        // box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.1);
        border-radius: 6px;
        margin-top: 12px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.1);
        font-size: 56px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0);
            box-shadow: 0 4px 35px 0 rgba(23, 55, 87, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
        }
        position: relative;
        overflow: hidden;
        z-index: 0;
    }
}
.fileSelectorContainerSmall {
    .filePlaceholder {
        transition: 0s;
        height: 40px;
        width: 170px;
        // box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.1);
        border-radius: 6px;
        margin-top: 12px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.1);
        font-size: 56px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        &:hover {
            border: 1px solid #5043dd;
            .btnText {
                position: absolute;
                top: 11px;
                left: 12px;
                font-size: 14px;
                color: #5043dd;
                font-weight: 400;
            }
            .btnTextIcon {
                margin-right: 10px;
                color: #5043dd;
            }
        }
        position: relative;
        overflow: hidden;
        z-index: 0;
    }
    .btnText {
        transition: 0s;
        position: absolute;
        top: 11px;
        left: 12px;
        font-size: 14px;
        color: gray;
        font-weight: 400;
    }
    .btnTextIcon {
        transition: 0s;
        margin-right: 10px;
        color: lightgray;
    }
}
.optionalsContainer {
    padding-bottom: 40px;
    display: inline-block;
    width: calc(50% - 20px);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    vertical-align: top;
}
.requiredContainer {
    display: inline-block;
    width: 30%;
    min-width: 300px;
    vertical-align: top;
}
.dropzoneSectionElement {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    input {
        width: 150px;
        height: 150px;
        opacity: 0;
        display: block !important;
        cursor: pointer;
    }
}
.imageSectionElement {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    input {
        display: none;
        cursor: pointer;
    }
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.loader {
    margin: auto;
    margin-top: -98px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(214, 214, 214, 0.4);
    border-right: 0.3em solid rgba(214, 214, 214, 0.4);
    border-bottom: 0.3em solid rgba(214, 214, 214, 0.4);
    border-left: 0.3em solid rgba(0, 0, 0, 0.2);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.imagePreview,
.pdfPreview {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
}
.pdfPreview {
    svg {
        position: absolute;
        display: block;
        margin: auto;
        top: 44px !important;
        left: 50%;
        transform: translateX(-22px);
    }
}
.uploadFileIcon {
    position: absolute;
    display: block;
    margin: auto;
    top: 44px !important;
    left: 50%;
    transform: translateX(-22px);
    z-index: 0 !important;
}
.pdf {
    position: absolute;
    top: 62px;
    left: 46px;
    font-size: 12px;
    font-weight: 800;
    background-color: #5043dd;
    padding: 2px 4px;
    color: white;
    opacity: 0.7;
}
.removeFile {
    background-color: black;
    padding: 0px 5px;
    height: 17px;
    border-radius: 10px;
    position: absolute;
    top: 6px;
    right: 6px;
    color: white;
    font-weight: 800;
    font-size: 12px;
    opacity: 0.3;
    z-index: 999;
    transform: scale(0.9);
    &:hover {
        opacity: 1;
    }
}
.dateModalContainer {
    width: 90vw;
    max-width: 320px;
    padding: 30px;
    display: flex;
}
.modalLabel {
    padding-left: 28;
    font-size: 14;
    margin: "20px 0px";
    color: "gray";
}
.modalActions {
    position: absolute;
    bottom: 0px;
    right: 8px;
    padding: 28px;
}
.datePicker {
    margin-left: "calc(50% - 156px)";
    width: 310px !important;
    margin-top: 50px;
}
