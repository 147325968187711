.container {
  transition: 0.5s;
  background-color: white;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 999999;
  color: #2a2651;
  font-size: 16px;
  font-weight: 500;
  animation: fadein 0.5s;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  label {
    font-size: 12px;
    color: #2a2651;
    font-weight: 500;
    padding: 12px 12px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  
  .content {
    padding: 30px 12px;
    width: 360px;
    height: 46px;
  }
  .fileName {
    display: inline-block;
    vertical-align: middle;
    margin-top: -26px;
    margin-left: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
  }
}
.svg {
  stroke: #2a2651;
  height: 50px;
  width: 50px;
  opacity: 0.1;
  margin-top: -6px;
}
.completedSvg {
  stroke: #5043dd;
  height: 50px;
  width: 50px;
  opacity: 1;
  margin-top: -6px;
}