
.dropzoneSectionElement {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    input {
        width: 150px;
        height: 150px;
        opacity: 0;
        display: block !important;
        cursor: pointer;
    }
}
.imageSectionElement {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    input {
        display: none;
        cursor: pointer;
    }
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}
.loader {
    margin-top: 26px;
    margin-left: 0px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(214, 214, 214, 0.4);
    border-right: 0.3em solid rgba(214, 214, 214, 0.4);
    border-bottom: 0.3em solid rgba(214, 214, 214, 0.4);
    border-left: 0.3em solid rgba(0, 0, 0, 0.2);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.imagePreview,
.pdfPreview {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
}
.pdfPreview {
    svg {
        position: absolute;
        display: block;
        margin: auto;
        top: 44px !important;
        left: 50%;
        transform: translateX(-22px);
    }
}
.uploadFileIcon {
    position: absolute;
    display: block;
    margin: auto;
    top: 44px !important;
    left: 50%;
    transform: translateX(-22px);
    z-index: 0 !important;
}
.pdf {
    position: absolute;
    top: 62px;
    left: 46px;
    font-size: 12px;
    font-weight: 800;
    background-color: #5043dd;
    padding: 2px 4px;
    color: white;
    opacity: 0.7;
}
.removeFile {
    background-color: black;
    padding: 0px 5px;
    height: 17px;
    width: 7px;
    border-radius: 10px;
    color: white;
    font-weight: 800;
    font-size: 12px;
    opacity: 0.3;
    z-index: 999;
    transform: scale(0.9);
    display: inline-block;
    &:hover {
        opacity: 1;
    }
    position: absolute;
    bottom: 39px;
    left: 176px;
    cursor: pointer;
}
.selectedFile {
    position: absolute;
    bottom: 18px;
    left: 30px;
    background-color: rgba(0, 0, 0, 0.06);
    padding: 6px 16px;
    border-radius: 16px;
    padding-right: 36px;
}