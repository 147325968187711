.RichEditor-root {
    background: #fff;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 14px;
    position: relative;
}

.RichEditor-editor {
    cursor: text;
    line-height: 20px;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 12px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 40px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
    position: absolute;
    bottom: -36px;
    left: 0px;
    font-weight: 600;
}

.RichEditor-styleButton {
    color: #ccc;
    cursor: pointer;
    margin-right: 16px;
    margin-left: -2px;
    padding: 2px 0;
    display: inline-block;
    &:hover {
        color: #666
    }
}
.RichEditor-controls ~ .RichEditor-controls {
    margin-left: 30px;
    font-size: 22px;
    font-weight: 100;
    transform: scaleY(1.6) translateY(-2px);
    padding-top: 3px;
}

.RichEditor-activeButton {
    color: #5043dd;
}
.public-DraftEditorPlaceholder-inner {
    position: absolute;
    top: 12px;
    color: lightgray;
}
.public-DraftEditorPlaceholder-root {
    padding-top: 0px !important;
    height: 3px !important;
}
.RichEditor-commentActions {
    position: relative;
    padding: 12px;
    padding-top: 6px;
    button {
        transition: .1s;
        position: absolute;
        right: -12px;
        bottom: -35px;
        color: #5043dd;
        font-weight: 500;
        background-color: white;
        opacity: 0.9;
        outline: none;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
            background-color: #f9f9f9;
        }
    }
}
