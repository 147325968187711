.title {
    text-align: center;
    margin-top: 20px;
    font-size: 28px;
}
.loaderContainer {
    height: 140px;
    width: 140px;
    display: block;
    position: relative;
    margin: auto;
    margin-top: 20vh;
    border-radius: 100px;
    opacity: 0.9;
    // overflow: hidden;
}
.hiddenMemeImage {
    transition: 1s;
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 100px;
    top: -28px;
    opacity: 0;
    transform: scale(0.1);
}
.memeImage {
    transition: 1s;
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 100px;
    top: -26px;
}
.verifiedButton {
    margin: auto;
    margin-top: 30px;
    width: fit-content;
    animation: fadein 4s;
    color: #5043dd;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    @keyframes fadein {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
    }
    a {
        text-decoration: none;
        font-weight: 500;
        color: #5043dd;
        &:hover {
            text-decoration: underline;
        }
    }
}

.ldsRipple {
    display: block;
    position: relative;
    width: 82px;
    height: 82px;
    margin: auto;
  }
  .ldsRipple div {
    position: absolute;
    border: 4px solid #5043dd;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .ldsRipple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
}
.verificationText {
    text-align: center;
    margin-top: 40px;
}
.verifyEmailPage {
    padding-top: 20vh;
}
.actionsDiv {
    padding-top: 20px;
    width: fit-content;
    margin: auto;
}