.backdrop {
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.06);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99900;
    backdrop-filter: blur(2px);
    animation: backdrop 0.3s ease-in-out;
}
.container {
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100vh;
    width: 100%;
    max-width: 420px;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s linear;
    // background-color: #fafafa;
}
.hiddenContainer {
    pointer-events: none;
    position: fixed;
    right: -880px;
    top: 0px;
    height: 100vh;
    width: 100%;
    max-width: 420px;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    animation: slideOut 0.4s linear;
    // background-color: white;
}
.hiddenBackdrop {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(2px);
    opacity: 0;
    left: 0px;
    z-index: 99900;
    animation: hideBackdrop 0.3s ease-in-out;
}
@keyframes slideIn {
    0% {
        opacity: 1;
        right: -480px;
    }
    100% {
        right: 0px;
    }
}
@keyframes backdrop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideOut {
    0% {
        opacity: 0.6;
        right: 0px;
    }
    100% {
        right: -420px;
        opacity: 0;
    }
}
@keyframes hideBackdrop {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.header {
    position: fixed;
    background-color: white;
    width: calc(100% - 40px);
    position: relative;
    padding: 24px;
    // padding-left: 50px;
    padding-left: 20px;
    height: 26px;
}
.arrowBack {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    stroke: #2a2651;
}
.title {
    color: #2a2651;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 600;
}
