.button {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    color: #555;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 22px;
    margin-top: 20px;
    margin-right: 10px;
    &:hover {
        border-color: black;
        color: black;
    }
}
.disabledButton {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    color: #555;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    cursor: default;
    // transition: all 0.2s ease-in-out;
    opacity: 0.5;
    background-color: #eee;
    margin-bottom: 22px;
    margin-top: 20px;
    margin-right: 10px;
}