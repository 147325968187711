.activityContainer {
    width: 100%;
    // border-top: 1px solid rgba(0,0,0,0.06);
    padding-top: 1px;
    margin-top: 40px;
    margin-bottom: 100px;
    position: relative;
}
.activityTitle {
    margin-top: 30px !important;
    font-size: 16px;
    font-weight: 500;
}
.avatars {
    display: inline-block;
    width: 35px;
    padding-left: 5px;
    margin-top: 20px;
    vertical-align: top;
    @media (max-width: 400px) {
        width: 0px;
        overflow: hidden;
    }
}
.avatarContainer {
    position: absolute;
    height: 100%;
    left: -34px;
    top: 0px;
    @media (max-width: 400px) {
        display: none;
    }
}
.avatar {
    width: 22px;
    height: 22px;
    background: #f9f9f9;
    border-radius: 30px;
    margin-bottom: 26px;
}
.commentAvatar {
    width: 28px;
    height: 28px;
    background: #f9f9f9;
    border-radius: 30px;
    margin-bottom: 26px;
    margin-left: -4px;
}
.commentAvatarActivity {
    font-size: 10px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 100px;
    padding-top: 4px;
    padding-left: 4px;
    color: white;
    font-weight: 800;
}
.commentAvatarContent {
    font-size: 13px;
    width: calc(100% - 5px);
    height: calc(100% - 6px);
    border-radius: 100px;
    padding-top: 6px;
    padding-left: 5px;
    color: white;
    font-weight: 600;
}
.actions {
    margin-top: 20px;
    padding-left: 35px;
    display: inline-block;
    width: calc(100% - 35px);
    vertical-align: top;
    @media (max-width: 400px) {
        padding-left: 0px;
        width: 100%;
    }
}
.actionContainer {
    padding-top: 3px;
    min-height: 19px;
    margin-bottom: 30px;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
    position: relative;
    b {
        font-weight: 500;
        color: rgba(0,0,0,0.8);
    }
}
.line {
    position: absolute;
    border-right: 1px solid rgba(0,0,0,0.08);
    height: 100%;
    width: 10px;
    top: 26px;
}
.commentContainer {
    transition: .1s;
    height: fit-content;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.06);
    border-radius: 6px;
    position: relative;
    &:focus-within {
        border: 1px solid rgba(190,190,190,0.12);
        box-shadow: rgba(0,0,0,0.07) 0px 0px 12px 0px;
    }
}
.comment {
    min-height: 64px;
    line-height: 18px;
    width: calc(100% - 44px);
    border: none;
    outline: none;
    padding: 21px;
    resize: none;
    border-radius: 8px;
    padding-bottom: 30px;
    font-size: 14px;
    word-wrap: break-word;
    &::placeholder {
        color: rgba(0,0,0,0.34);
    }
    padding-top: 6px;
}
.commentActions {
    position: relative;
    padding: 12px;
    padding-top: 6px;
    button {
        transition: .1s;
        position: absolute;
        right: 12px;
        bottom: 12px;
        color: #5043dd;
        font-weight: 500;
        background-color: white;
        opacity: 0.9;
        outline: none;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
            background-color: #f9f9f9;
        }
    }
}
.hiddenInput {
    height: 1px;
    border: none;
    outline: none;
    opacity: 0;
    margin: 0px;
    padding: 0px;
}
.commentActivity {
    background-color: #f6f6f6;
    width: calc(100% - 40px);
    border-radius: 8px;
    border-top-left-radius: 0px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 20px;
    padding-bottom: 8px;
    word-wrap: break-word;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 50, 0.06);
    border: 1px solid rgba(0,0,0,0.1);
    background-color: #fff;
    p {
        margin-top: 0px;
        line-height: 17px;
        font-weight: 400;
        color: rgba(0,0,0,0.8);
        font-size: 13px;
    }
    li {
        font-size: 13px;
        margin-top: 0px;
        margin-bottom: 11px;
        line-height: 17px;
        font-weight: 400;
        color: rgba(0,0,0,0.8);
        margin-left: 10px;
        &::marker {
            font-size: 14px;
            margin-right: 0px;
        }
        span {
            margin-left: -8px;
        }
    }
}
.activityDate {
    display: inline-block;
    margin-left: 8px;
    color: rgba(0, 0, 50, 0.36);
    vertical-align: top;
    margin-top: 0px;
    font-size: 12px;
}