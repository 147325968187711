.fieldValue {
    width: calc(100% - 18px);
    height: 22px;
    padding: 3px 12px;
    padding-top: 8px;
    border-radius: 1px;
    vertical-align: middle;
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    color: #2a2651;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: 0.2s;
    padding-left: 8px;
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
        padding-left: 8px;
        width: calc(100% - 18px);
    }
}

.lockedFieldValue {
    width: calc(100% - 18px);
    height: 22px;
    padding: 3px 12px;
    padding-top: 8px;
    border-radius: 1px;
    vertical-align: middle;
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    color: #2a2651;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: 0.2s;
    padding-left: 8px;
}
.noValue {
    font-style: italic;
    opacity: 0.7;
}
