.plans {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 70vh;
    overflow: auto;
    flex-direction: row;
    padding-top: 60px;
    // border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.plan {
    width: 200px;
    max-height: calc(100% - 70px);
    margin-right: 10px;
    border-radius: 10px;
    padding: 30px;
    padding-top: 20px;
    margin-left: -20px;
    margin-right: 40px;
    padding-bottom: 50px;
    text-align: center;
    box-shadow: 0 4px 22px rgba(80, 0, 80, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.1);
    animation: fadeInCard 0.4s;
    transform: scale(0.95);
    @keyframes fadeInCard {
        0% {
            opacity: 0.4;
            margin-left: -30px;
        }
        100% {
            opacity: 1;
            margin-left: -20px;
        }
    }
}
.standardPlan {
    width: 200px;
    max-height: calc(100% - 70px);
    margin-right: 10px;
    border-radius: 10px;
    padding: 30px;
    padding-top: 20px;
    margin-left: -20px;
    margin-right: 40px;
    padding-bottom: 50px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(0, 0, 0, 0.1);
    animation: fadeInCard 0.4s;
    @keyframes fadeInCard {
        0% {
            opacity: 0.4;
            margin-left: -30px;
        }
        100% {
            opacity: 1;
            margin-left: -20px;
        }
    }
}
.planSkeleton {
    width: 260px;
    max-height: calc(100%);
    margin-right: 10px;
    border-radius: 10px;
    margin-left: -20px;
    margin-right: 40px;
    text-align: center;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
    // border: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(0, 0, 0, 0.03);
    overflow: hidden;
}
.name {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
    color: gray;
}
.price {
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 20px;
    color: #5043dd;
    margin-bottom: 40px;
    span {
        color: rgba(0, 0, 0, 0.3);
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 4px;
    }
}
.infoText {
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 30px;
    color: gray;
    text-align: center;
}
.separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.06);
    margin-top: 20px;
    margin-bottom: 20px;
}
.button {
    margin: auto;
    margin-top: 60px;
}
.currentPlan {
    max-height: calc(100% - 70px);
    margin-right: 40px;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    font-size: 0.9rem;
    font-weight: 500;
    width: 120px;
    margin: auto;
    margin-top: -2px;
}
.fieldValue {
    position: relative;
    width: calc(100% - 18px);
    height: 22px;
    padding: 3px 12px;
    padding-top: 8px;
    border-radius: 1px;
    vertical-align: middle;
    margin-top: 0px;
    margin-bottom: -8px;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    color: #2a2651;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    padding-left: 8px;
    z-index: 1;
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
        padding-left: 8px;
        width: calc(100% - 18px);
    }
}
.noValue {
    opacity: 1;
}
.chevronIcon {
    position: absolute;
    right: 10px;
    top: 9px;
    padding: 0px;
    opacity: 0.2;
    transform: scaleX(1.2);
}
.dropdownMenu {
    width: 100%;
    height: 84px;
    background-color: white;
    position: absolute;
    top: 36px;
    left: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 20px 0px;
    border-radius: 4px;
    cursor: default;
    padding-top: 12px;
    // border: 1px solid rgba(0,0,0,0.06);
}
.dropdownOption {
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.billingLabel {
    margin-bottom: 12px;
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 7px;
}
.suscription {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 100px;
    max-width: 500px;
    margin-bottom: 60px;
    margin-top: 1px;
    position: relative;
}
.planName {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    left: 26px;
    top: 26px;
}
.planPrice {
    position: absolute;
    font-size: 20px;
    right: 26px;
    top: 26px;
    color: #5043dd;
}
.planFrecuency {
    position: absolute;
    font-size: 12px;
    right: 26px;
    top: 56px;
    color: rgba(0, 0, 0, 0.5);
}
.planUsers {
    position: absolute;
    font-size: 12px;
    left: 26px;
    top: 52px;
    color: rgba(0, 0, 0, 0.5);
}
.planAction {
    position: absolute;
    font-size: 13px;
    font-weight: 600;
    right: 1px;
    bottom: -27px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:hover {
        color: #5043dd;
    }
}
.creditCard {
    display: block;
    position: absolute;
    top: 32px;
    left: 26px;
    opacity: 0.5;
}
.cardNumber {
    display: block;
    width: 200px;
    position: absolute;
    top: 42px;
    left: 68px;
    opacity: 0.5;
    font-size: 16px;
}
.nextBillingInfo {
    display: block;
    width: 200px;
    opacity: 0.5;
    font-size: 14px;
}