.layoutTooltip {
    width: 290px;
    max-height: 370px;
    overflow: hidden;
    padding-bottom: 8px;
    z-index: 1000;
    pointer-events: auto;
    border-radius: 6px;
    label {
        font-size: 12px;
        color: #2a2651;
        font-weight: 500;
        padding: 12px 12px;
        display: block;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    p {
        display: inline-block;
        vertical-align: middle;
        margin: 10px;
    }
    svg {
        height: 20px;
        width: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 2px;
        stroke: gray;
    }
}
.searchInput {
    width: 100%;
    height: 10px;
    outline: none;
    border: none;
    transition: 0.1s;
    padding: 4px 20px;
    font-size: 13px;
    width: calc(100% - 40px);
    height: 20px;
    margin: auto;
    color: gray;
    transition: .2s;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 10px;
    padding-top: 12px;
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }
    &:focus {
        border-bottom: 1px solid #5043ddcc;
    }
}
.optionsDiv {
    max-height: 300px;
    overflow-x: hidden;
}
.optionDiv {
    transition: 0.1s;
    padding: 4px 12px;
    font-size: 13px;
    width: calc(100% - 20px);
    margin: auto;
    cursor: pointer;
    color: gray;
    transition: .2s;
    &:hover {
        background-color: #fafafa;
        color: #2a2651 !important;
        svg {
            stroke: #2a2651 !important;
        }
        .stateIndicator {
            height: 0px;
            width: 0px;
            border: 5px solid;
        }
    }
}
.stateIndicator {
    transition: .2s;
    height: 8px;
    width: 8px;
    border-radius: 20px;
    background: rgba(255, 205, 0, 0.6);
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    background-color: white;
    border: 1px solid;
}
