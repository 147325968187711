.settingsPage {
    padding-bottom: 80px;
    padding-top: 1px;
}
.loadingSettingsPage {
    padding-bottom: 80px;
    padding-top: 1px;
    opacity: 0.8;
}
.settingsPaper {
    background: white;
    margin: auto;
    width: 100%;
    border-radius: 6px;
    // box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}
.loadingPaper {
    background: white;
    margin: auto;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
    opacity: 0.7;
}
.setting,
.disabledSetting {
    // height: 150px;
    // margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.07);
    width: calc(100% - 46px);
    padding: 22px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 40px;
    border-radius: 6px;
    padding-bottom: 76px;
    overflow: hidden;
    max-width: 800px;
}
.settingSwitch {
    // position: absolute !important;
    // top: 18px;
    // left: 14px;
    margin-left: -10px;
    margin-top: -6px;
    transform: scale(0.9);
}
.settingTittle {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 7px;
}
.settingText {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
}
.settingFooter {
    background-color: rgba(0, 0, 0, 0.02);
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
}
.disabledSetting .settingAction {
    margin-left: 24px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(97, 74, 217, 0.2);
    cursor: default;
    pointer-events: none;
    &:hover {
        .actionIcon {
            margin-left: 4px;
        }
    }
}
.settingAction {
    margin-left: 24px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #5043dd;
    cursor: pointer;
    width: fit-content;
    &:hover {
        .actionIcon {
            margin-left: 8px;
        }
    }
}
.actionIcon {
    transition: 0.3s;
    font-size: 11px;
    margin-left: 4px;
}
.backArrow {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    vertical-align: top;
    padding: 12px;
    margin-top: 4px;
    padding-left: 0px;
    cursor: pointer;
    &:hover {
        color: black;
    }
}
.sectionTitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    display: inline-block !important;
    vertical-align: middle;
    font-weight: 600;
    margin-left: 8px;
    margin-bottom: 38px;
}
.category {
    padding: 18px;
    padding-left: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    position: relative;
}
.customField {
    padding: 18px;
    padding-left: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    position: relative;
    width: calc(100% - 36px);
    // height: 60px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 50, 0.06);
    max-width: 600px;
}
.customFieldTitle {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
}
.customFieldType {
    margin-top: 0px;
    font-size: 12px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    padding: 4px 8px;
    display: inline-block;
    color: #5043dd;
}
.customFieldsContainer {
    padding-bottom: 30px;
}
.deleteCategory {
    height: 20px;
    stroke: rgba(180, 180, 180, 1);
    vertical-align: middle;
    padding: 9px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        stroke: rgba(255, 60, 60, 1);
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.editCategory {
    height: 20px;
    stroke: rgba(180, 180, 180, 1);
    vertical-align: middle;
    padding: 9px;
    position: absolute;
    right: 48px;
    top: 8px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        stroke: black;
        background-color: rgba(0, 0, 0, 0.04);
    }
}
.addCategoryButton {
    margin-left: 100px;
    float: right;
}
.dialogInput {
    border: 1px solid lightgray;
    background: white;
    border-radius: 4px;
    padding: 12px;
    width: calc(85% + 2px);
    outline: none !important;
    margin-left: 6px;
    margin-top: 6px;
}
.dialogLabel {
    margin-top: 20px;
    color: gray;
    margin-left: 6px;
    font-size: 13px;
}
.dialogButton {
    position: absolute;
    bottom: 42px;
    right: 40px;
}
.dialogContent {
    margin-top: 24px;
    margin-left: 0px;
    min-width: 380px;
    padding-left: 6px;
}
.list {
    width: calc(100% + 32px);
}
.cardIcon {
    color: lightgray;
    font-size: 22px;
    margin-bottom: 12px;
    position: absolute;
    top: 26px;
}
.cardContent {
    z-index: 100;
}
.modalLabel {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    margin-top: 20px;
}
.selectButton {
    cursor: pointer;
    display: inline-block;
    border: 1px solid lightgray;
    color: gray;
    padding: 12px 10px;
    font-size: 13px;
    margin-right: 10px;
    border-radius: 6px;
    transition: 0.2s;
    font-weight: 600;
    &:hover {
        border-color: gray;
    }
}
.selectedPM {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #5043dd;
    font-weight: 600;
    padding: 12px 20px;
    font-size: 13px;
    margin-right: 10px;
    border-radius: 6px;
    color: #5043dd;
    transition: 0.2s;
    // font-weight: bold;
}
.modalInput {
    border: 1px solid lightgray;
    background: white;
    border-radius: 4px;
    padding: 12px;
    width: 422px;
    max-width: 92%;
    outline: none !important;
}
.autocompleteInputBar {
    background: white;
    font-family: "Quicksand";
    padding: 12px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid #d4d9e2 !important;
    width: 422px;
    max-width: 92%;
    font-size: 14px;
    outline: none !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}
.smallModalInput {
    border: 1px solid lightgray;
    background: white;
    border-radius: 4px;
    padding: 12px;
    width: 122px;
    max-width: 92%;
    outline: none !important;
}
.selectBar {
    background: white;
    padding: 12px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid #d4d9e2 !important;
    width: 422px;
    max-width: 92%;
    font-size: 14px;
    outline: none !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 15px);
    background-position-y: 11px;
    cursor: pointer;
}
.simpleInput {
    margin-top: 0px;
    position: relative;
}
.selectMenu {
    background: white;
    position: absolute;
    height: 150px;
    padding: 0px;
    width: 442px;
    max-width: calc(90% + 20px);
    left: 0%;
    top: 54px;
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow-y: scroll;
    z-index: 1;
}
.noResultsText {
    color: rgba(0, 0, 0, 0.4) !important;
    background: white !important;
    cursor: default !important;
}
.optionResult {
    padding: 10px 12px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
        background: rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }
}
.nextButton {
    position: absolute;
    bottom: 32px;
    right: 32px;
}
.membersList {
    width: 98%;
    height: 210px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.01);
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
}
.memberOption {
    width: 95%;
    padding: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.02);
    }
}
.avatar {
    height: 32px !important;
    width: 32px !important;
    margin-top: 2px;
    margin-left: 6px !important;
    margin-right: 14px;
    display: inline-block;
    vertical-align: middle;
}
.selectAll {
    color: #5043dd;
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
    border-radius: 6px;
    position: fixed;
    top: 109px;
    left: 10px;
    padding: 0px 10px;
    background-color: rgb(251,251,251);
    width: 83%;
    z-index: 10;
}
.selectAll p {
    width: 120px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.dataLabel {
    font-size: 10px;
    display: inline-block;
    font-weight: 600;
    margin-top: 18px;
    color: rgba(0,0,0,0.7);
    transform: translateY(6px);
    margin-left: 42px;
}
.modalText {
    margin-left: 6px;
    width: 400px;
}
.cardNumber {
    margin-left: 42px;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.6);
}
.dataText {
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: gray;
    transform: translateY(6px);
}
.hr {
    margin-bottom: 80px;
    margin-top: 80px;
    background-color: rgba(0,0,0,0.1);
    height: 1px;
    width: 100%;
    max-width: 860px;
}